<template>
    <div :class="s.container">
        <div :class="s.content">
            <p @click="goBack" :class="s.goBack">
                ← Go back
            </p>
            <div :class="s.topIconContainer">
                <div :class="s.round">
                    <Icon
                        icon="code_totp"
                        :class="s.icon"
                    />
                </div>
            </div>
        </div>
        <div :class="s.module">
            <div :class="s.header">
                <span :class="s.headerText">
                    Code Security Verification
                </span>
            </div>
            <div :class="s.inputBlock">
                <div :class="s.inputHeaderContainer">
                    <span :class="s.subheaderText">
                        Enter 6 digit code from Google Authenticator
                    </span>
                </div>
                <form @submit.prevent="returnCode">
                    <SeparatedDigitsInput
                        id-prefix="code"
                        :on-change="onChange"
                        :number-of-digits="6"
                        :class="s.mbL"
                        autofocus
                        ref="TotpInput"
                    />
                    <Button
                        wide
                        is-submit
                        button-type="primary"
                        :button-disabled="isButtonDisabled"
                    >
                        <template>
                            Verify
                        </template>
                    </Button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import Icon from 'UI/Icon.vue';
import SeparatedDigitsInput from 'Control/SeparatedDigitsInput.vue';
import Button from 'Control/Button.vue';

export default {
    name: 'MFA_TOTP',
    components: {
        Button,
        Icon,
        SeparatedDigitsInput,
    },
    data() {
        return {
            code: '',
            isButtonDisabled: false,
        };
    },
    computed: {
        previousRoute() {
            return this.$store.state.Auth.lastRoute;
        },
    },
    methods: {
        async returnCode() {
            if (this.code.length !== 6) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Please enter 6 digits code');
                return;
            }
            this.isButtonDisabled = true;
            await this.$store.dispatch('Auth/resolveGetMFATokenChallenge', this.code);
            if (this.$refs.TotpInput) {
                this.$refs.TotpInput.clearInput();
            }
        },
        onChange(value) {
            this.code = value;
        },
        goBack() {
            if (this.previousRoute) {
                this.$router.push(this.previousRoute).catch(() => { /* navigation error */ });
                return;
            }

            if (window.history.length > 2) {
                this.$router.back();
            } else {
                this.$router.push('/terminal').catch(() => { /* navigation error */ });
            }
        },
    },
    created() {
        if (!this.$store.getters['Auth/isGetMFATokenChallengeActive']) {
            this.$router.replace('/profile').catch(() => { /* navigation error */ });
        }
    },
    destroyed() {
        if (this.$store.getters['Auth/isGetMFATokenChallengeActive'] && this.$store.getters['Auth/isGetMFATokenChallengeActiveMfaType'] === MFA_ENROLL_FACTOR_TYPES.TOTP) {
            this.$store.dispatch('Auth/clearGetMFATokenChallengeHandlers');
        }
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(2 * var(--m-xxxl));
    width: 100%;
    height: 100%;
    & .content {
        min-width: 350px;
    }
    & .topIconContainer {
        display: flex;
        justify-content: center;
        margin-bottom: var(--m-l);
        & .round {
            width: 138px;
            height: 138px;
            background: var(--cl-white);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.module {
    background-color: var(--cl-white);
    border-radius: 8px;
    position: relative;
}
.headerText {
    font-weight: var(--fw-bold);
    font-size: var(--fs-xl);
    line-height: var(--fs-xl);
    text-transform: uppercase;
    color: var(--cl-black);
}
.subheaderText {
    font-weight: var(--fw-regular);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-black);
}
.header {
    padding: var(--m-m);
    border-bottom: 2px solid var(--cl-gray-light);
    display: flex;
    align-items: center;
}
.inputBlock {
    padding: var(--m-xxl);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.icon {
    width: 51px;
    height: 86px;
    color: var(--cl-violet);
}
.goBack {
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-violet);
    cursor: pointer;
    margin-bottom: var(--m-l);
}
.inputHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--m-m);
}
.mbL {
    margin-bottom: var(--m-l);
}
</style>
